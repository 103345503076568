import React, {Component} from 'react'
import {useTranslation, withTranslation} from "react-i18next";
import { connect } from 'react-redux';
import {
  toggleDarkMode
} from '../../store/actions/ThemeAction';

function Translate(props)
{
    const [t, i18n] = useTranslation();
    return <div>
        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="#" style={{color: props.style.icon}}>
            <i className="fas fa-flag-usa"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-sm-right">
          <a href="#" onClick={() => i18n.changeLanguage('en')} className="dropdown-item">ENG
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" onClick={() => i18n.changeLanguage('es')} className="dropdown-item">ESP
            </a>
          </div>
        </li>
    </div>
}const mapStateToProps = (state) => ({
  style : state.ThemeReducer
})



const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleDarkMode())
})


export default connect(mapStateToProps,mapDispatchToProps)(Translate)