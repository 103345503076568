import React, { useEffect, useState } from "react";
// import { UserPanel } from "./UserPanel";
import Accesos from "./Accesos";
import { useHistory } from "react-router-dom";
// import logo from "../../img/Logo.jpg";
import logo from './../../img/logo-cassa.jpg'
import logo2 from './../../img/cassa.png'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Axios from "axios";
import { path } from "../../config";
import { toggleDarkMode } from '../../store/actions/ThemeAction';
import dataIcon from '../../img/paper1.svg'
import Siembra from '../../img/Siembra.svg'
import Help from '../../img/Help.svg'
import Paper from '../../img/paper1.svg'
import {server_folder} from '../../config'
const MenuLateral = (props) => {
  const { Usuario, Vars, menuLat, Ingenio,style } = props;
   console.log("🚀 ~ file: MenuLateral.js ~ line 18 ~ MenuLateral ~ props", props)
  let dato = Usuario?.sociedad || [];
  let data = Vars;

  
  let nombreIngenio = document.getElementById("Ingenio_Seleccionado")?.textContent;
  // const menu = data2[0].Hijos;
  const history = useHistory();
  const url = 'https://www.grupocassa.com/'
  const redirigir = () => {
    history.push({
        pathname: `${server_folder}/Encuesta`
    });
};
const encuesta = () => {
  history.push({
      pathname: `${server_folder}/Encuesta`
  });
};
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4" id="menu" style={{ background: props.style.body, borderColor: props.style.body, zIndex: 1 }}>
      <Link to="./" className="brand-link">
        {/* <img src={logo2} className="logo" /> */}
        <span className="brand-text font-weight-light">Logistica Viajes</span>
      </Link>

      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex" style={{ borderColor: props.style.body }}>
          {/* <div className="image">
            <img src="./../dist/img/user7-128x128.jpg" className="img-circle elevation-2" alt="User Image" />
          </div> */}
          {/* <div className="info">
            <a href="#" className="d-block" style={{color: props.style.icon}}>Carla Patiño</a>
          </div> */}
        </div>

        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" >
            {
              menuLat.map((e, index) => {
                // if (el.rolPermitted && el.rolPermitted.some( ai => props.user.RoleName && props.user.RoleName.split(',').includes(ai) )) {
                  return <Accesos item={e} key={index} />
                // } else {
                //   return <></>
                // }
              }
              )
            }
            
           
          </ul>
        </nav>
      </div>
    </aside>
  )
}

const mapStateToProps = (state) => ({
  Usuario: state.UserReducer,
  Vars: state.IngenioReducer,
  style: state.ThemeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleDarkMode())
})
export default connect(mapStateToProps, mapDispatchToProps)(MenuLateral);