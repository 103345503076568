import React from 'react';
import {
    Link
} from "react-router-dom";
import { connect } from 'react-redux';
import {
    toggleDarkMode
} from '../../store/actions/ThemeAction';


function Accesos(props ) {
    const { item } = props;
    function splitiado (e){
        let divisiones = e?.split("/", 3) 
    // console.log(divisiones[1] ? divisiones[1]  || " " )

    let division=divisiones?.filter(e => e )

        // console.log("🚀 ~ file: Accesos.js ~ line 18 ~ Accesos ~ division", division)
    //    let n= division.filter(Boolean);
    //    console.log("🚀 ~ file: Accesos.js ~ line 21 ~ Accesos ~ n", n)
        return  division?.length > 0 ? division[1] : 'inicio'
    }
    return (
        <li className="nav-item has-treeview" >
            <Link to={`${item.href}`} className="nav-link" style={{ color: props.style.icon }}>
                <i className={item.class}></i>
                <p>
                    {item.title}
                    {
                        item.length> 0 ?
                   
                            <i className='fas fa-angle-left right'></i>
                            :
                            <></>

                    }
                    {/* <span className="badge badge-info right">6</span> */}
                </p>
            </Link>
             {/* <ul className="nav nav-treeview">
                {
                    item.subMenu.map((e, index) => {
                             return <li key={index || ''} className="nav-item">

                             <Link to={`${e.href}`} className="nav-link" style={{ color: props.style.icon }}>
                                    <i className={e.class}></i> 
                                    <p>
                                        {e.title}
                                        {
                                         e.Hijos && !!e.Hijos.length && <i className='fas fa-angle-left right'></i>
                                        }
                                    </p>
                                </Link>        
                            </li>
                    })
                }
             </ul>  */}
        </li>
    )


}

const mapStateToProps = (state) => ({
    style: state.ThemeReducer,
    user: state.UserReducer
})



const mapDispatchToProps = (dispatch) => ({
    toggle: () => dispatch(toggleDarkMode())
})

export default connect(mapStateToProps, mapDispatchToProps)(Accesos)


