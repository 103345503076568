import React, { useEffect, useState } from 'react'
import Translate from '../Elementos/Translate'
import FiltroZafra from '../Menu/Zafras'
// import { Link } from 'react-router-dom'
import { message } from 'antd';
import { connect } from 'react-redux';
import {
  toggleDarkMode
} from '../../store/actions/ThemeAction';
import Settings from '../../img/Settings.svg'
import Help from '../../img/Help.svg'
import LogOut from '../../img/Log-out.svg'
import { useHistory } from 'react-router-dom';
import { logout } from '../../store/actions/UserAction';
import { setNotifications } from '../../store/actions/NotificationsAction'
import { setLoading } from '../../store/actions/LoadingAction';
import { PUSHER_URL, PUSHER_APP_KEY, server_folder, path } from '../../config'
import { Alert } from '../Elementos/Alert'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { useSelector } from 'react-redux'
import { rutas } from '../../routes'
import { ENV } from '../../config'
import Notifications_Container from './Notifications'
import Redirect from '../Redirect';

function Header(props) {

  const location = useLocation();
  const user = useSelector(state => state.UserReducer)
  const [connected, setConnected] = useState(false)

  //Guardar estadisticas de visitas
  const history = useHistory()

  const redirigir = () => {
    //return <Redirect to={`${server_folder}/Login`}/>
    //window.location.href = `${rutas.Launchpad}`
    window.location.href = `${server_folder}/Login`;
  }
  const Logout = () => {
   // props.setLoading(true)
    redirigir()
   // props.logout()
  }
  const getInitials = (name) => {
    if (!name) return ''

    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light " id="head" style={{ position: 'inherit', backgroundColor: props.style.body, borderColor: props.style.body }}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button" style={{ color: props.style.icon }}><i className="fas fa-bars"></i></a>
        </li>

      </ul>
      <form className="form-inline ml-3">
        <div className="input-group input-group-sm">
          <div
            className="col-lg-8 user-name"
            onClick={() => Logout()}>
            <p>Salir</p>
          </div>
        </div>
      </form>
      <ul className="navbar-nav ml-auto ">

        {/* <FiltroZafra /> */}
        <Translate />

        {/* Esto es una prueba cons stash */}
        <li className="nav-item dropdown">
          <a className="nav-link user-margen" data-toggle="dropdown" href="#">
            <div className="circulo2">
              <p className="user">{getInitials(props.user.FullName)}</p>
              <div className="status_circle" style={{ backgroundColor: connected ? 'green' : 'gray' }}></div>
            </div> </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="#" className="dropdown-item">
              <div className="media">
                {/* <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" /> */}
                <div className="media-body">
                  <div className="row">
                    <div className="col-lg-2">
                      <div className="circulo2">
                        <p className="user-collapse">{getInitials(props.user.FullName)}</p>
                      </div>
                    </div>
                    <div className="col-lg-8 user-name">
                      <p>{props.user.FullName}</p>
                    </div>
                  </div>

                </div>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item" >
              <div className="media">
                {/* <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" /> */}
                <div className="media-body">
                  <div className="row">
                    <div className="col-lg-2">
                      <img src={Settings}></img>
                    </div>
                    <div className="col-lg-8 user-name">
                      <p>Configuraciones</p>
                    </div>
                  </div>

                </div>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item" >
              <div className="media">
                {/* <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" /> */}
                <div className="media-body">
                  <div className="row">
                    <div className="col-lg-2">
                      <img src={Help}></img>
                    </div>
                    <div className="col-lg-8 user-name">
                      <p>FAQ's</p>
                    </div>
                  </div>

                </div>
              </div>
            </a>
            <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item" >
              <div className="media">
                {/* <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" /> */}
                <div className="media-body">
                  <div className="row">
                    <div className="col-lg-2">
                      <img src={LogOut}></img>
                    </div>
                    <div
                      className="col-lg-8 user-name"
                      onClick={() => Logout()}>
                      <p>Salir</p>
                    </div>
                  </div>

                </div>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </nav>
  )
}

const mapStateToProps = (state) => ({
  style: state.ThemeReducer,
  user: state.UserReducer,
  notifications: state.NotificationsReducer
})



const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleDarkMode()),
  logout: () => dispatch(logout()),
  setLoading: (loading) => dispatch(setLoading(loading)),
  setNotifications: (notifications) => dispatch(setNotifications(notifications)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)