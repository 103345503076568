import { SET_INGENIO } from '../actions/IngenioAction'


const initialState = !!localStorage.getItem('Variables') ? JSON.parse(localStorage.getItem('Variables')) : {
    Vars: {
        IdIngenio: 'Seleccione Ingenio',
        IdZafra: 'Seleccione Zafra',
      }
}



export const IngenioReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INGENIO:
            localStorage.setItem('Variables', JSON.stringify({ ...state, ...action.payload}));
            return { ...state, ...action.payload }
        default:
            return state
    }
}

