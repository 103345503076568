import React, { useEffect, lazy } from "react";
import Menu from "./pages/Menu";
import "antd/dist/antd.css";
import "handsontable/dist/handsontable.full.css";

import { BrowserRouter as Router } from "react-router-dom";

const Lazy_Enrutador = lazy(() => new Promise(async resolve => {
  const module = await import("./pages/Menu/Enrutador");
  setTimeout(() => resolve(module), 1000);
}));


function App(props) {

  return (
      <Router>
        <Menu />
        <Lazy_Enrutador />
      </Router>
  );
}

export default App;
