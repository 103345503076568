import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
//----
import { connect,useSelector } from 'react-redux';
import { path } from '../../config'
import {
    setUser
} from '../../store/actions/UserAction';
import { server_folder } from '../../config'
import { rutas } from '../../routes'
import {
    setIngenio
} from '../../store/actions/IngenioAction';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function Redirect(props) {
    const [listo,setListo] = useState(false)

    const { guardarUsuario, login2, cambiarIngenio } = props
    const history = useHistory()
    let query = useQuery()

    let token = query.get('token');
    let login = query.get('login')
    let BkAplicacion = query.get('bk_aplicacion')
    let IdSesion = query.get('idsesion')
    let AplicacionId = 0
    const ip = query.get('ipusuario')

    const user =  useSelector(state => state.UserReducer)
    console.log("🚀 ~ file: index.js ~ line 35 ~ Redirect ~ user", user)

    

    let Usuario = 0

    

    const comparar = async () => {
        try {
            const { data } = await Axios.get(`${rutas.Token}`, { params: { login, token } })         
                let pathname = 'Inicio'
                const data4 = await Axios.get(`${rutas.ApiSeguridad}GetIdUsuario?Login=${login}`,
                    {
                        headers: {
                            "Authorization": "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl")
                        }
                    },
                )
                Usuario = data4.data.IdUsuario
                console.log("🚀 ~ file: index.js ~ line 62 ~ comparar ~ Usuario", Usuario)
                if(login==null && !user.isLogued){
                  window.location.href =`${rutas.Launchpad}`
                }
                const aplicacion = await Axios.get(`${rutas.ApiSeguridad}GetAplicacion?BkAplicacion=${BkAplicacion}`, { headers: { "Authorization": "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl") } })
                const menu = await Axios.get(`${rutas.ApiSeguridad}GetObjetosSeguridadxAplicacion?IdAplicacion=${aplicacion.data.IdAplicacion}&IdUsuario=${Usuario}`, { headers: { "Authorization": "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl") } })
                const dataUsuario = await Axios.get(`${rutas.ApiSeguridad}GetUsuario?IdUsuario=${Usuario}`,
                    {
                        headers: {
                            "Authorization": "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl")
                        }
                    },

                )
                
                const dataRoles = await Axios.get(`${rutas.ApiSeguridad}GetUsuarioXRol?IdUsuario=${Usuario}`,
                    {
                        headers: {
                            "Authorization": "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl")
                        }
                    },

                )
                const zafra = await Axios.get(`http://192.168.24.181/api_control_malezas/api/DatosMaestros/GetZafraActiva`,)              
                cambiarIngenio({ IdIngenio: 1000, IdZafra: zafra.data[0].IdZafra, Nombre: 'Central Izalco' })
                const u = dataUsuario.data                 
                const usuarioData = {
                    Activo: u.Activo,
                    Apellidos: u.Apellidos,
                    Bloqueado: u.Bloqueado,
                    Celular: u.Celular,
                    Creacion: u.Creacion,
                    DsGrupoUsuario: u.DsGrupoUsuario,
                    Email: u.Email,
                    FinValidez: u.FinValidez,
                    Foto: u.Foto,
                    IdGrupoUsuario: u.IdGrupoUsuario,
                    IdUsuario: u.IdUsuario,
                    InicioValidez: u.InicioValidez,
                    login: u.Login,
                    LoginUsuarioCreacion: u.LoginUsuarioCreacion,
                    FullName: u.Nombres + " " + u.Apellidos,
                    NumeroEmpleado: u.NumeroEmpleado,
                    Telefono: u.Telefono,
                    UltimoCambioPassword: u.UltimoCambioPassword,
                    UsuarioCreacion: u.UsuarioCreacion,
                    IdAplicacion: aplicacion.data.IdAplicacion,
                    IdSesion: IdSesion,
                    ip: ip,
                    sociedad: 1000,
                    IdAplicacion: aplicacion.data.IdAplicacion,
                }
                login2({ ...usuarioData, isLogued: true })
                console.log("🚀 ~ file: index.js ~ line 190 ~ comparar ~ usuarioData", usuarioData)
                const datosUser = localStorage.getItem('Usuario')
                history.push({
                    pathname
                });
                setListo(true)

        } catch (error) {
        console.log("🚀 ~ file: index.js ~ line 135 ~ comparar ~ error", error)
        }
    }

    const ConstruirMenu = async () => {
        const menu = await Axios.get(`${rutas.ApiSeguridad}GetMenus?IdAplicacion=${18}&IdUsuario=${258}`, { headers: { "Authorization": "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl") } })
    }
    const Login_Exitoso = ({ RoleName }) => {

    }
    const dUser = localStorage.getItem('Usuario')

    const IsLogged = async () => {
        if (!user.isLogued){
            window.location.href =`${rutas.Lanzador}`
        }
    }


    useEffect(() => {
        comparar()
    }, [])

    return (
        <>
        </>
    );
}
const mapDispatchToProps = (dispatch) => ({
    login2: (user) => dispatch(setUser(user)),
    cambiarIngenio: (ingenio) => dispatch(setIngenio(ingenio))
})



export default connect(null, mapDispatchToProps)(Redirect)