import { TOGGLE_SWITCHER } from '../actions/ThemeAction'
import { LIGHT_MODE, DARK_MODE } from './../../assets/style'

const initState = !!localStorage.getItem('isDarkMode') ? JSON.parse(localStorage.getItem('isDarkMode')) : {
    isDarkMode: false,
    ...LIGHT_MODE
}

export const ThemeReducer = (state = initState, action) => {
    switch (action.type) {
        case TOGGLE_SWITCHER:
            const isDarkMode = !state.isDarkMode;
            const colorObject = isDarkMode ? DARK_MODE : LIGHT_MODE
            localStorage.setItem('isDarkMode', JSON.stringify( { ...state, isDarkMode: !state.isDarkMode, ...colorObject }));
            return { ...state, isDarkMode: !state.isDarkMode, ...colorObject }

        default:
            return state
    }
}