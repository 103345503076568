import React, { Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import "./i18n";
import store from "./store";
import { Provider } from "react-redux";
import Loading from './pages/Elementos/Loading'
import "leaflet/dist/leaflet.js";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <ConfigProvider locale={es_ES}>
          <App/>
        </ConfigProvider>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
