import { combineReducers } from 'redux';


import {ThemeReducer} from '../reducers/ThemeReducer';
import { LoadingReducer } from '../reducers/LoadingReducer'
import { UserReducer } from '../reducers/UserReducer'
import {IngenioReducer} from '../reducers/IngenioReducer';
import {NotificationsReducer} from '../reducers/NotificationsReducer'
import { ZafraReducer } from '../reducers/ZafraReducer';

const rootReducer = combineReducers({
    ThemeReducer,LoadingReducer,UserReducer,IngenioReducer,NotificationsReducer, ZafraReducer
});

export default rootReducer;