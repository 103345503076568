import { SET_LOADING } from '../actions/LoadingAction'

const initState = {
    isLoading: true
}

export const LoadingReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state,isLoading: action.payload }

        default:
            return state
    }
}