import { CHANGE_ZAFRA } from '../actions/ZafraActions'

const initState = {ZafraSeleccionada: null}

export const ZafraReducer = (state = initState, action) => {
    switch (action.type) {
        case CHANGE_ZAFRA:

            return { ...state, ZafraSeleccionada: action.payload }

        default:
            return state
    }
}