import { SET_USER } from '../actions/UserAction'
import { LOGOUT } from '../actions/UserAction'

const initState = !!localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {
    isLogued : false
}
export const UserReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_USER:
            localStorage.setItem('user', JSON.stringify( { ...state,...action.payload }));
            return { ...state,...action.payload }
        case LOGOUT:
            
            localStorage.setItem('user', JSON.stringify( { isLogued: false}));
            return { isLogued: false }

        default:
            return state
    }
}