import React from 'react';
import {  notification  } from 'antd';
import {  CheckCircleTwoTone,WarningTwoTone, InfoCircleTwoTone, BellTwoTone  } from '@ant-design/icons';

export const Alert = (message,description,icon) => {
    let Icono = null
    let Place = 'topRight'

    const Context = React.createContext({ name: 'Default' });

    switch (icon) {
        case 'error':
            Icono = <WarningTwoTone twoToneColor="#dc3545"/>
            break;

        case 'info':
            Icono = <InfoCircleTwoTone twoToneColor="#22A3C2"/>
            break;
        
        case 'notificacion':
            Icono = <BellTwoTone twoToneColor="#249CC2" />
            Place = 'bottomLeft'
            break;
    
        default:
            Icono = <CheckCircleTwoTone twoToneColor="#52c41a" />
            break;
    }
    notification.open({
      message,
      description,
      icon: Icono,
      placement: Place
    });
};
