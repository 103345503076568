import React, { useEffect, useState } from "react";
import barco from "./../../img/Barco.gif";
import { toggleDarkMode } from "../../store/actions/ThemeAction";
import { connect } from "react-redux";
import Axios from "axios";
import { rutas } from "../../routes";
function Loading(props) {
  const { Usuario, Vars, menuLat, Ingenio, style } = props;



  return (
    <div className="logo-loading">
      <img style={{ width: "15%" }} src={barco}></img>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Usuario: state.UserReducer,
  Vars: state.IngenioReducer,
  style: state.ThemeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(toggleDarkMode()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Loading);

// export default Loading
