import React from 'react';
import moment from 'moment';
import 'moment/locale/es'
moment.locale('es')


const Notifications_Container = ({ notifications,MarcarComoLeido }) => {
    const { data } = notifications
    return (
        <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#"
            // style={{ color: props.style.icon }}
            >
                <i className="far fa-bell"></i>
                <span className="badge badge-warning navbar-badge">{data.length || ''}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{height: '250px',overflowX : 'scroll'}}>
                {
                    data.map(n => {
                        return (
                            <Notifications_Item notification={n} MarcarComoLeido={MarcarComoLeido}/>

                        )
                    })
                }
            </div>
        </li>

    )
}


const Notifications_Item = ({ notification: n,MarcarComoLeido }) => {
    const ago = moment(n.Fecha_Notificacion).fromNow();
    return (
        <>
            <a href="#" className="dropdown-item" onClick={() =>MarcarComoLeido(n)}>
                <h3 className="dropdown-item-title">
                    {n.DS_TipoNotificacion}
                    <span className="float-right text-sm text-danger">
                        {/* <img src={Embarque}></img> */}
                    </span>
                </h3>
                {/* <p className="text-sm">{n.DS_TipoNotificacion}</p> */}
                <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> {ago}</p>
            </a>
            <div className="dropdown-divider"></div>
        </>
    )
}





export default Notifications_Container;