import { SET_NOTIFICATIONS } from '../actions/NotificationsAction'
const initState = {
    data : []
}
export const NotificationsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return { ...state, data : [...action.payload] }
        default:
            return state
    }
}