// import React from 'react'
// import Header  from './Header'
// import  MenuLateral  from './MenuLateral'

// const Menu = () => (
//     <div className="hold-transition skin-blue sidebar-mini menu">

//         <Header />
//         <MenuLateral />

//     </div>
// )

// export default Menu;

import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from './Header'
import MenuLateral from './MenuLateral'
import { rutas } from '../../routes'
import {data} from './DataMenu'
import {data2} from './DataMenuPiloto'
// import Footer from './footer'
const Menu = (props) => {
    // alert('sjhs')
    let menuData = []
    const { Ingenios, Vars } = props
    // console.log("🚀 ~ file: index.js ~ line 27 ~ Menu ~ props", props)
    const [menuLat, setMenuLat] = useState([])
    const [zafra, setZafra] = useState({})
    const [Ingenio, setIngenio] = useState()
    const [count, setCount] = useState(-1);

    let DatosUsuario = []
    useEffect(() => {
        ConstruirMenu()
    }, [])

    // useEffect(() => {
    //     setTimeout(function () {
    //         setCount(count + 1)
    //         // alert("Test")
    //         ConstruirMenu()
    //         let nom = 'Cargando Ingenio...'
    //         setIngenio(Ingenios.Nombre || nom)
    //         // // console.log("🚀 ~ file: index.js ~ line 27 ~ Menu ~ props", props)
    //     }, 2000)

    // }, [count])
    let nuevo = data;
    
    if(props.Vars.esAdmin === true){
        nuevo=data
        console.log('auqi en daa menu',nuevo);
    }
    else{
        
        nuevo=data2
        console.log('auqi en daa menu2',nuevo);
    }

    const ConstruirMenu = async () => {


        try {

          //  const menu = await Axios.get(`${rutas.ApiSeguridad}GetMenus?IdAplicacion=${Vars.IdAplicacion}&IdUsuario=${Vars.IdUsuario}`, { headers: { "Authorization": "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl") } })

          
          setMenuLat(nuevo || [])
           // let nuevo = data;
           
            // console.log("🚀 ~ file: index.js ~ line 60 ~ ConstruirMenu ~ nuevo", nuevo) 
           // setMenuLat(nuevo || [])
            // const aplicacion = await Axios.get(
            //     `${rutas.ApiSeguridad}IsLogged?IdSesion=${Vars.IdSesion}&IdAplicacion=${Vars.IdAplicacion}&IpCliente=${Vars.ip}`,
            //     {
            //       headers: {
            //         Authorization: "Basic " + btoa("AppClient" + ":" + "7TkXFQ3YvjGl"),
            //       },
            //     }
            //   );
      
        }
        catch (e) {
            // // console.log("🚀 ~ file: MenuLateral.js ~ line 45 ~ ConstruirMenu ~ e", e)

        }
    }
    return (
        <>
            <div className="hold-transition skin-blue sidebar-mini menu">

                <Header />
                <MenuLateral menuLat={menuLat} Ingenio={Ingenio} />
            </div>
           
        </>
    )
}


const mapStateToProps = state => ({
    Vars: state.UserReducer,
    Ingenios: state.IngenioReducer

})

export default connect(mapStateToProps, null)(Menu)