const COLORS = {
    black: '#000917',
    white: '#fff',
    gray: '##b8b8b9',
    secondaryBlack: '#091a34',
    menuLateralBorder: '#93a0ac',

    //White Colors Pallete
    primaryWhite: '#2C5282',
    secondaryWhite: '#4D73A1',
    thirtyWhite: '',
    secondaryWhite2: '#2C5282',

    //Black Colors Pallete
    primaryDarth: '#000917',
    secondaryDarth: '#08162C',
    thirtyDarth: '#0D1A2F',
    secondaryDarth2: '#051022'


}

export const LIGHT_MODE = {
    body: COLORS.white,
    icon: COLORS.black,
    borderMenu: COLORS.secondaryBlack,
    encabezado:COLORS.secondaryWhite2,
    container: COLORS.thirtyWhite
}


export const DARK_MODE = {
    body: COLORS.black,
    icon: COLORS.white,
    borderMenu: COLORS.menuLateralBorder,
    encabezado:COLORS.thirtyDarth,
    container:COLORS.secondaryDarth2



}

export default COLORS;