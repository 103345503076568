import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { server_folder, path } from '../../config'
import axios from 'axios'
import { Alert } from '../Elementos/Alert'
import { cambio_Zafra_Global } from '../../store/actions/ZafraActions';

function FiltroZafra(props) {
    // console.log("FiltroZafra ~ props", props)
    const { t } = props
    const [zafras, setZafras] = useState([]);

    useEffect(() => {
        Obtener_Zafras()
    }, []);

    const Obtener_Zafras = async () => {
        try {
            const { data: { data } } = await axios.get(`${path}/ControlExportacion/obtenerZafras`)

            const zafraActiva = data.filter(e => e.IdEstado == 1)

            props.toggle(zafraActiva[0].BkZafra)

            const zafrasShow = data.filter(e => e.BkZafra == +zafraActiva[0].BkZafra - 1 || e.BkZafra == zafraActiva[0].BkZafra || e.BkZafra == +zafraActiva[0].BkZafra + 1)

            setZafras(zafrasShow)
        } catch (error) {
            Alert("Advertencia", "Obtener", 'error')
        }
    }

    return (
        <div>
            {
                zafras.length > 0 &&
                <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#" style={{ color: props.style.icon }} title="Zafra">
                        <i className="fas fa-calendar-week"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm-right">
                        <a href="#" onClick={() => props.toggle(zafras[0].BkZafra)} className="dropdown-item">{zafras[0].DsZafra}
                        </a>
                        <div className="dropdown-divider"></div>
                        <a href="#" onClick={() => props.toggle(zafras[1].BkZafra)} className="dropdown-item">{zafras[1].DsZafra}
                        </a>
                        <div className="dropdown-divider"></div>
                        <a href="#" onClick={() => props.toggle(zafras[2].BkZafra)} className="dropdown-item">{zafras[2].DsZafra}
                        </a>
                    </div>
                </li>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    zafra: state.ZafraReducer,
    style : state.ThemeReducer
})

const mapDispatchToProps = (dispatch) => ({
    toggle: (payload) => dispatch(cambio_Zafra_Global(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(FiltroZafra)