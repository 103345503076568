import {ENV} from './config'

// const ENV='DEV'
const Routes =

{
    LOCAL: {
        Launchpad: 'http://192.168.24.178/lanzador',
        ApiSeguridad: 'http://192.168.24.178/API_Seguridad/index.php/Api/',
        UserName: 'AppClient',
        Password: '7TkXFQ3YvjGl',
        Token: 'http://192.168.24.178/lanzador/index.php/Api/check_token',
        Lanzador: 'http://192.168.24.178/lanzador/index.php/Login/logout'
    },
    QAS: {
        Launchpad: 'http://192.168.24.181/lanzador',
        ApiSeguridad: 'http://192.168.24.181/API_Seguridad/index.php/Api/',
        UserName: 'AppClient',
        Password: '7TkXFQ3YvjGl',
        Token: 'http://192.168.24.181/lanzador/index.php/Api/check_token',
        Lanzador: 'http://192.168.24.181/lanzador/index.php/Login/logout'
    },
    DEV: {
        Launchpad: 'http://192.168.24.178/lanzador/index.php/Inicio',
        ApiSeguridad: 'http://192.168.24.178/API_Seguridad/index.php/Api/',
        UserName: 'AppClient',
        Password: '7TkXFQ3YvjGl',
        Token: 'http://192.168.24.178/lanzador/index.php/Api/check_token',
        Lanzador: 'http://192.168.24.178/lanzador/index.php/Login/logout'
    },
    PRD: {
        Launchpad: 'http://168.243.156.98/lanzador',
        ApiSeguridad: 'http://168.243.156.98/API_Seguridad/index.php/Api/',
        UserName: 'AppClient',
        Password: '7TkXFQ3YvjGl',
        Token: 'http://168.243.156.98/lanzador/index.php/Api/check_token',
        Lanzador: 'http://168.243.156.98/lanzador/index.php/Login/logout'
    }
}

export const rutas = Routes[ENV]