import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import {ENV} from './config'
import {server_folder} from './config'

const backend = ENV !== "LOCAL" ? {
  loadPath: `${server_folder}/locales/{{lng}}/{{ns}}.json`
} : {}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'es',
  debug: false,
  detection: {
    order: ['queryString', 'cookie'],
    cache: ['cookie']
  },
  interpolation: {
    escapeValue: false
  },
  backend
})

export default i18n